body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDataGrid-cellContent {
  white-space: pre-line;
}

.MuiDataGrid-row :hover{
  cursor: pointer;
}

.cs_sample_header{
  variant: overline;
  display: block;
}

.cs_sample_sample_details{
  font-size: 1.125rem;
  font-weight: bold;
}

#verification_table .css-gl260s-MuiDataGrid-columnHeadersInner{
  background-color: #F4F6F8;
  color: #637381;
  font-size: 0.875rem;
}

.acoustics-modal{
  display: grid;
  grid-template-columns: 1fr;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 700px;
  max-height: 100%;
  width: 600px;
  max-width: 100%;
  padding: 10px 10px;
  border: 2px solid #000;
  background-color: palegoldenrod;
  row-gap: 5px;
  overflow: auto;
}

.acoustics-modal-heading{
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 1px 1px;
  font-size: 20px;
  text-align: center;
}

.acoustics-modal-row{
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
  font-size: 14px;
  text-align: center;
  display: grid;
  row-gap: 2px;
}

.acoustics-modal-row-item{
  padding: 1px 1px;
}